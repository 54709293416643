/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressTable {
    margin-block-end: 2rem;
    border-bottom: 1px solid var(--color-input-border);

    .KeyValueTable {
        &-Wrapper {
            max-width: 60%;
            border-bottom: none;

            * {
                word-break: break-all;
            }
        }
    }

    .Link {
        &-EditAddressButton {
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 19%;
            border-bottom: 1px solid var(--color-black);
            padding-block-end: 2px;
            text-transform: uppercase;
            font: 1.2rem var(--font-FFDIN);
            font-weight: normal;
            width: auto;

            @include isArabic {
                font: 1.2rem var(--font-DINArabic);
            }

            &:hover {
                text-decoration: none;
                color: var(--color-red);
                border-bottom: 1px solid var(--color-red);
            }

            @include mobile {
                font-size: .8rem;
            }
        }

        &-DeleteAddressButton {
            width: auto;
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
            text-transform: uppercase;
            font: 1.2rem var(--font-FFDIN);
            border-bottom: 1px solid var(--color-black);
            padding-block-end: 2px;

            @include isArabic {
                font: 1.2rem var(--font-DINArabic);
            }

            &:hover {
                text-decoration: none;
                color: var(--color-red);
                border-bottom: 1px solid var(--color-red);
            }

            @include tablet {
                font-size: 1rem;
                inset-block-start: 1px;
            }

            @include mobile {
                font-size: .8rem;
                inset-block-start: 1px;
            }
        }
    }

    td,
    th {
        @include after-mobile {
            width: 50%;
        }
    }
}
