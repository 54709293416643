/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressBook {
    @include mobile {
        border-bottom: 1px solid var(--color-input-border);
        padding-block-end: 2.5rem;
    }

    .Link {
        &-AddAddressButton {
            font: 1.2rem var(--font-FFDIN);
            border-bottom: 1px solid var(--color-black);
            padding-block-end: 1px;
            text-transform: uppercase;
            font-weight: bold;
            width: auto;

            @include isArabic {
                font: 1.2rem var(--font-DINArabic);
            }

            &:hover {
                text-decoration: none;
                color: var(--color-red);
                border-bottom: 1px solid var(--color-red);
            }

            @include tablet {
                font-size: 1rem;
            }

            @include mobile {
                font-size: .7rem;
                border-bottom: 1px solid black;
                padding-block-end: 2px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        &-EditAddressButton {
            @include tablet {
                font-size: 1rem;
            }
        }
    }

    p {
        font: 1.8rem var(--font-FFDIN);
        margin: -.5rem 0 3.8rem;

        @include isArabic {
            font: 1.8rem var(--font-DINArabic);
        }

        @include mobile {
            font-size: 1rem;
            margin: 0;
            padding-block-end: 2rem;
        }
    }

    .KeyValueTable {
        font-family: var(--font-FFDIN);

        @include isArabic {
            font-family: var(--font-DINArabic);
        }
    }
}
