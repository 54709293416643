/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.Account-Info {
    .Field_type_checkbox:has(.FieldSubscribe_IsUaeWebsite) {
        display: none;
    }
}

.MyAccountInformation {
    &-ButtonWrapper {
        width: 100%;
    }

    &-NewsletterIframeButton {
        background: none;
        border: none;
        width: fit-content;
        font-size: 14px;
        max-width: fit-content;
        padding-block-end: 2px;
        text-transform: uppercase;
        font-weight: normal;
        border-bottom: 1px solid var(--color-black);

        &:hover {
            color: var(--color-red);
            border-bottom: 1px solid var(--color-red);
        }
    }
}
