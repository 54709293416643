/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --my-account-content-background: #{$white};

    @include after-mobile {
        --my-account-background: var(--secondary-base-color);
    }
}

.MyAccount {
    background: var(--color-white);
    padding-block-end: 3rem;

    &-LogoutWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-Header {
        section {
            padding: var(--header-padding);
            background-color: var(--header-background-color);

            .PageHeader {
                padding-inline-start: 3rem;
                padding-inline-end: 3rem;

                .MyAccount {
                    &-Heading {
                        @include mobile {
                            font: 1.2rem var(--font-FFDIN);
                        }

                        @include isArabic {
                            font: 1.2rem var(--font-DINArabic);
                        }
                    }

                    &-Profile {
                        @include tablet {
                            font-size: 2rem;
                        }

                        @include mobile {
                            font: 1.2rem var(--font-FFDIN);
                        }

                        @include isArabic {
                            font: 1.2rem var(--font-DINArabic);
                        }
                    }

                    &-LogoutWrapper {
                        button {
                            font: 1.5rem var(--font-FFDIN);
                            text-transform: uppercase;

                            @include isArabic {
                                font: 1.5rem var(--font-DINArabic);
                            }

                            @include tablet {
                                font-size: 1.2rem;
                            }

                            @include mobile {
                                font-size: .7rem;
                            }

                            &::after {
                                content: '';
                                border-bottom: 1px solid var(--color-black);
                                display: block;
                            }

                            &:hover::after {
                                border-bottom-color: red;
                            }

                            &:hover {
                                color: red;
                            }
                        }
                    }
                }
            }
        }
    }

    @include mobile {
        padding-block-end: 0;
        margin-block: 0;
    }

    .Profile {
        display: flex;
        justify-content: space-between;
        background: #eee;
        padding: 0 128px 0;
        align-items: center;
        text-transform: uppercase;

        @include tablet {
            padding: 0.2rem 2.9rem 0.5rem;
            margin-block-start: -1rem;
        }

        @include mobile {
            padding: 0 1rem;
            inset-block-start: -1rem;
        }

        .Logout {
            font-size: 1.4rem;
            font-weight: normal;

            @include tablet {
                font-size: 1rem;
            }

            @include mobile {
                font-size: .7rem;
            }

            Link {
                &-Char {
                    font-size: 2rem;
                }
            }
        }

        h2 {
            font: 2rem var(--font-FFDIN);

            @include isArabic {
                font: 2rem var(--font-DINArabic);
            }

            @include tablet {
                font-size: 1.6rem;
            }

            @include mobile {
                font-size: 1rem;
            }
        }

        .MyAccountTabListItem {
            &-Button {
                font: 1.5rem var(--font-FFDIN);
                line-height: 1.1;
                display: inline-block;
                border-bottom: 1px solid var(--color-black);
                text-shadow: 0 0 0 var(--color-black);
                position: relative;
                inset-block-start: .2rem;

                @include isArabic {
                    font: 1.5rem var(--font-DINArabic);
                }

                @include mobile {
                    font: 1rem var(--font-FFDIN);

                    @include isArabic {
                        font: 1rem var(--font-DINArabic);
                    }
                }
            }
        }
    }

    &-Wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 6.8rem 7.6rem;
        margin-block-end: 3rem;

        @include tablet {
            padding: 3.5rem 1rem;
        }

        @include mobile {
            flex-direction: column;
            margin-block-start: 3rem;
        }

        .Account {
            &-Info,
            &-Password,
            &-Addresses,
            &-Orders {
                width: 44%;

                #chck1,
                #chck2,
                #chck3,
                #chck4 {
                    display: none;
                }

                .tab {
                    width: 100%;
                    color: var(--color-black);
                    overflow: hidden;

                    &-label {
                        font: 1.9rem var(--font-FFDIN);
                        border-bottom: 1px solid var(--color-input-border);
                        margin: 0 0 3.1rem;
                        padding: 0 0 1.1rem;
                        font-weight: bold;

                        @include isArabic {
                            font: 1.9rem var(--font-DINArabic);
                        }

                        @include tablet {
                            font-size: 1.3rem;
                        }

                        @include mobile {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            cursor: pointer;
                            font: 1.2rem var(--font-FFDIN);
                            font-weight: bold;
                            border-bottom: 1px solid var(--color-input-border);
                            margin: 0;
                            padding-block-end: 10px;

                            @include isArabic {
                                font: 1.2rem var(--font-DINArabic);
                            }

                            &::after {
                                content: '\002B';
                                width: 1em;
                                height: 1em;
                                text-align: center;
                                transition: all .35s;
                                font-size: 1.5rem;
                                color: var(--color-black);
                                opacity: .4;
                            }
                        }
                    }

                    &-content {
                        @include mobile {
                            max-height: 0;
                            color: var(--color-black);
                            background: white;
                            transition: all .35s;
                        }
                    }

                    &-close {
                        @include mobile {
                            display: flex;
                            justify-content: flex-end;
                            padding: 1em;
                            font-size: 0.75rem;
                            color: var(--color-black);
                            cursor: pointer;
                        }
                    }

                    input:checked {
                        + .tab-label {
                            background-color: transparent;
                            border-bottom: 1px solid var(--color-input-border);

                            &::after {
                                content: '';

                                @include mobile {
                                    content: '\002D';
                                }
                            }
                        }

                        ~ .tab-content {
                            max-height: 100vh;
                        }
                    }
                }

                @include mobile {
                    width: 92%;
                    margin: 0 auto;
                }
            }

            &-Orders {
                margin-block-start: 2rem;
            }

            &-Info,
            &-Password {
                .FieldForm-Fields {
                    @include mobile {
                        margin-block-start: 2rem;
                    }

                    .Field_type_checkbox label:first-of-type {
                        padding-inline-start: 2.5rem;
                    }

                    .FieldInput {
                        &-Wrapper {
                            width: 65%;

                            @include tablet {
                                width: 90%;
                                height: 40px;
                                margin-block-end: 14px;
                            }

                            @include mobile {
                                width: 100%;
                            }
                        }

                        &-Fields {
                            .Icon-CheckGreen {
                                position: absolute;
                            }
                        }

                        &-Error {
                            @include mobile {
                                font: .7rem var(--font-FFDIN);
                                position: relative;

                                @include isArabic {
                                    font: .7rem var(--font-DINArabic);
                                }
                            }
                        }
                    }
                }
            }

            &-Password {
                margin-block-end: 5.1rem;

                @include mobile {
                    margin-block-end: 0;
                }

                .Icon {
                    &-CheckGreen {
                        position: absolute;
                        margin-inline-start: 2rem;
                        margin-block-start: 1rem;
                        height: 12px;
                        width: 13px;

                        html[dir='rtl'] & {
                            margin-inline-start: 0;
                            margin-inline-end: 1rem;
                        }

                        @include mobile {
                            position: absolute;

                            html[dir='rtl'] & {
                                margin-inline-end: -2rem;
                            }
                        }
                    }
                }

                .FieldInput {
                    &-Wrapper {
                        @include tablet {
                            margin-block-end: 5px;
                        }

                        @include mobile {
                            padding-block-end: 0;
                            margin-block-end: 5px;
                        }
                    }

                    &-Error {
                        @include mobile {
                            inset-block-start: -.5rem;
                        }
                    }
                }

                .Button {
                    @include mobile {
                        margin-block-end: 2rem;
                    }
                }
            }

            &-Info {
                .FieldForm-Fields {
                    @include mobile {
                        margin-block-start: 2rem;
                    }
                }

                .Icon {
                    &-CheckGreen {
                        position: absolute;
                        inset-block-start: 1rem;
                        inset-inline-end: -3rem;

                        @include mobile {
                            position: absolute;
                        }
                    }
                }

                .Button {
                    margin-block-start: 2rem;

                    @include mobile {
                        margin-block-start: 10px;
                        margin-block-end: 2rem;
                    }
                }

                .FieldInput {
                    &-Error {
                        @include mobile {
                            inset-block-start: .6rem;
                        }
                    }
                }

                .Field_type_checkbox {
                    @include mobile {
                        padding-block-start: 1rem;
                    }

                    label:first-of-type {
                        // Cant override without important
                        /* stylelint-disable-next-line declaration-no-important */
                        padding-inline-start: 1rem !important;

                        html[dir='rtl'] & {
                            padding-inline-end: 1rem;
                            padding-inline-start: 0;
                        }

                        inset-block-start: 3px;

                        ~ label {
                            margin-inline-end: 0;

                            html[dir='rtl'] & {
                                margin-inline-start: 0;
                            }
                        }
                    }
                }
            }

            &-Addresses {
                inset-block-start: 5.1rem;

                @include mobile {
                    inset-block-start: 0;
                }

                .MyAccountAddressBook {
                    @include mobile {
                        border-bottom: 1px solid var(--color-input-border);
                        padding-block-end: 2rem;
                        padding-block-start: 2rem;
                    }

                    &-Button {
                        position: relative;
                        inset-block-start: 0;
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px solid var(--color-black);
                        color: var(--color-black);
                        padding: 0;
                        width: 30%;
                        text-transform: uppercase;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        font: 1.4rem var(--font-FFDIN);
                        display: inline-block;
                        line-height: 1.2;
                        font-weight: bold;
                        text-align: start;

                        @include isArabic {
                            font: 1.4rem var(--font-DINArabic);
                        }

                        @include mobile {
                            font-size: 1rem;
                            width: 37%;
                        }

                        &:hover {
                            color: var(--color-red);
                            border-bottom: 1px solid var(--color-red);
                        }
                    }

                    p {
                        font: 1.8rem var(--font-FFDIN);

                        @include isArabic {
                            font: 1.8rem var(--font-DINArabic);
                        }

                        margin: -.5rem 0 3.8rem;

                        @include mobile {
                            font-size: 1rem;
                            margin: 0;
                        }
                    }
                }
            }
        }

        @include mobile {
            padding: 0;
            grid-template-columns: 1fr;
        }
    }

    &-TabContent {
        background: var(--my-account-content-background);
        padding: 1rem;

        @include after-mobile {
            grid-row: span 2;
            padding: 2rem;
        }
    }

    &-Heading {
        font-size: 20px;
        font-weight: 400;
        text-transform: none;
        margin: 0 0 2rem;
    }

    &-Button,
    button {
        @include mobile {
            width: 100%;
        }
    }

    &SignIn-LoginButton {
        .LoginButton {
            @include mobile {
                font: .7rem var(--font-FFDIN);

                @include isArabic {
                    font: .7rem var(--font-DINArabic);
                }
            }
        }
    }
}
