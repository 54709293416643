/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountDashboard {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;

    @include desktop {
        grid-template-columns: 1fr 1fr;
        grid-gap: 4rem;
    }

    &-DefaultAddress {
        @include desktop {
            grid-row: 2;
        }
    }

    &-CustomerData {
        @include desktop {
            grid-column: span 2;
        }
    }

    &-Info {
        margin-block-end: 5px;
    }

    button + button {
        margin-block-start: 1rem;

        @include after-mobile {
            margin-inline-start: 1rem;
        }
    }
}
