/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressPopup {
    &_isVisible {
        height: 100%;
        inset-block-start: 0;

        @include mobile {
            width: 100%;
            background-color: var(--color-white);
        }
    }

    .Popup {
        &-Content {
            padding: 30px 10px 20px;
            overflow-y: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            min-width: 90%;

            @include mobile {
                min-width: 100%;
                padding: 30px 0 20px;
                overflow-y: hidden;
                height: 100%;
                display: flex;
                flex-direction: column;
                max-height: unset;
            }

            @include after-mobile {
                gap: 0;
                max-height: 800px;
                height: calc(100% - 40px);
                min-width: 52rem;
                margin: 20px 0;
                padding: 30px 45px 20px;
            }
        }

        &-Header {
            display: flex;
            position: relative;
            padding-inline-end: 1rem;

            @include mobile {
                width: 90%;
                margin: 0 auto;
            }

            &::after {
                content: '';
                position: absolute;
                width: calc(100%);
                height: 1px;
                background-color: var(--color-grey-c);
                inset-block-end: 0;
                inset-inline-start: 0;

                @include after-mobile {
                    width: calc(100% + 90px);
                    inset-inline-start: -45px;
                }
            }

            h2 {
                font-size: 14px;

                @include after-mobile {
                    font-size: 26px;
                }
            }

            button {
                margin-inline-start: auto;

                @include isArabic {
                    margin-inline-start: 0;
                }

                &,
                &::before,
                &::after {
                    inset-block-start: 0;
                }
            }
        }

        &-CloseBtn {
            @include mobile {
                @include isArabic {
                    inset-inline-start: 1rem;
                }
            }
        }
    }

    .CheckoutNewAddressForm {
        height: calc(100vh - 95px);

        @include after-mobile {
            height: calc(100% - 75px);
        }

        .Form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-FieldInputs {
            border-bottom: none;
            overflow-y: auto;
            max-height: 100%;
        }

        .FieldSelect {
            &-Options {
                background-color: var(--color-white);
                border: 1px solid var(--color-input-border);

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        &-Actions {
            padding-block-start: 1rem;

            @include mobile {
                margin-block-end: 42px;
            }
        }
    }
}
