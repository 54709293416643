/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.SignInPage {
    & .Login {
        display: flex;
        justify-content: center;

        @include desktop {
            padding: 5.3rem 4.1rem 7.1rem 0;
        }

        @include tablet {
            padding: 4.5rem 0;
        }

        & .LoginForm {
            border-right: 0.2rem solid var(--color-input-border);

            &-Container {
                margin-inline-end: 10.5rem;

                @include tablet {
                    margin-inline-end: 2rem;
                }

                & .Field-Wrapper {
                    input {
                        width: 30rem;
                    }
                }

                & .LoginTitle {
                    & h3 {
                        &::after {
                            content: '';
                            border-bottom: 0.2rem solid var(--color-input-border);
                            display: block;
                            padding-block-start: 0;
                            width: 18.3rem;
                        }

                        color: var(--color-gray-title);
                        font: bold 1.6rem var(--font-FFDIN);
                        letter-spacing: -.05rem;
                        line-height: 3.2rem;
                        margin-block-end: 2.1rem;
                        margin-block-start: 1.3rem;
                    }
                }
            }
        }

        & .Register {
            &-Subscription {
                max-width: 28rem;

                @include tablet {
                    max-width: 23rem;
                }
            }

            &Form {
                &-Container {
                    margin-inline-start: 6.7rem;

                    @include tablet {
                        margin-inline-start: 5.7rem;
                    }

                    & .FieldInput-Wrapper {
                        input {
                            width: 30rem;

                            @include tablet {
                                width: 24rem;
                            }
                        }
                    }

                    & .RegistrationTitle {
                        & h3 {
                            color: var(--color-gray-title);
                            font: bold 1.6rem var(--font-FFDIN);
                            letter-spacing: -.05rem;
                            line-height: 3.2rem;
                            margin-block-end: 2.1rem;
                            margin-block-start: 1.3rem;

                            &::after {
                                content: '';
                                border-bottom: 0.2rem solid var(--color-input-border);
                                display: block;
                                padding-block-start: 0;
                                width: 18.3rem;
                            }
                        }
                    }

                    .Field {
                        .Icon {
                            &-CheckGreen {
                                height: 12px;
                                inset-block-start: 15px;
                                inset-inline-end: -3rem;
                                position: absolute;
                                width: 13px;
    
                                @include tablet {
                                    inset-inline-end: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.MyAccount-Header {
    section {
        padding: var(--header-padding);
        background-color: var(--header-background-color);
    }
}
