/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountOrderPopup {
    .Popup {
        &-Content {
            overflow-x: hidden;
        }
    }

    .RowDetails {
        &-Arabic {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }

    &-ProductsWrapper {
        overflow-x: auto;
    }

    &-Address,
    &-Products {
        margin-block-end: 1rem;

        @include after-mobile {
            margin-block-start: 2rem;
        }

        @include mobile {
            margin-block-end: 2rem;
        }
    }

    &-Products {
        width: 100%;
        margin-block-start: 1rem;
        table-layout: initial;

        td,
        th {
            vertical-align: middle;
        }
    }

    &-TrackShipmentButton {
        font-weight: bold;
        text-align: start;
        max-width: fit-content;
        padding-block-start: 10px;

        @include animated-bottom-border(var(--color-black), var(--color-red));
    }

    &-RowDetails {
        display: flex;
        flex-direction: column;

        b {
            font-weight: bold;
            margin-inline-start: 3px;
        }
    }

    &-Thumbnail {
        width: 60px;
        height: 60px;
        padding: 0;
    }

    &-Row {
        height: 80px;
        padding: 10px 0;

        &:nth-child(even) {
            img {
                mix-blend-mode: multiply;
            }
        }
    }

    &-Content {
        min-height: 200px;

        @include after-mobile {
            display: grid;
            grid-gap: 2rem;
        }
    }

    h4 {
        font-size: 1.2rem;
    }

    dt {
        font-weight: bold;
    }

    dl {
        display: grid;
        margin-block-start: 1rem;
        grid-template-columns: 1fr 1fr;
        grid-gap: .5rem;

        @include mobile {
            margin-block-end: 2rem;
        }
    }

    td,
    th {
        min-width: 0;
    }
}
