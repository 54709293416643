/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.MyAccountSignIn {
    .Icon-CheckGreen {
        position: absolute;
        inset-block-start: 17px;
        inset-inline-end: 1rem;
        width: 13px;
        height: 12px;

        @include desktop {
            inset-inline-end: -3rem;
        }
    }

    &-LoginButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-block-start: 15px;

        @include desktop {
            flex-direction: row;
        }

        button {
            width: 50%;
            font-size: 16px;

            @include mobile {
                font-size: 12px;
            }
        }

        &_isCheckout {
            justify-content: space-between;

            @include desktop {
                max-width: 87%;
            }

            button {
                width: 100%;
                font-size: 12px;

                @include desktop {
                    width: unset;
                }
            }
        }

        .Button {
            &-Forgot {
                font: 12px var(--font-FFDIN);
                font-weight: bold;
                width: auto;
                text-transform: uppercase;
                margin: 10px 0 0 auto;

                @include isArabic {
                    font: 12px var(--font-DINArabic);

                    @include mobile {
                        margin: 10px auto 0 0;
                    }
                }

                @include desktop {
                    margin: 0 0 0 15px;

                    html[dir='rtl'] & {
                        margin: 0 15px 0 0;
                    }
                }

                &::after {
                    content: '';
                    height: 1px;
                    border-bottom: 1px solid var(--color-black);
                    display: block;
                    margin: 0 auto;
                    position: relative;

                    @include tablet {
                        width: 83px;

                        @include isArabic {
                            width: auto;
                        }
                    }

                    &:hover {
                        display: none;
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .LoginMessage {
        @include desktop {
            font-size: 14px;
        }
    }

    .LoginForm {
        &-PolicyMessage {
            a + a {
                margin-inline-start: unset;
            }
        }
    }
}
