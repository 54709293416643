/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

:root {
    --my-account-light-gray: #eee;
}

.SignInPage {
    .Login {
        display: flex;
        justify-content: center;
        padding: 5.3rem 4.1rem 7.1rem 0;

        @include mobile {
            padding: 0;
            margin: 0;
            inset-block-start: -5rem;
        }

        @include tablet {
            padding: 4.5rem 0;
        }

        .MobileLoginForm {
            .MobileLoginForm {
                border-bottom: .2rem solid var(--color-input-border);
                padding-block-end: 2.7rem;

                @include after-mobile {
                    border-bottom: none;
                }
            }
        }

        &-Dashboard {
            height: var(--header-height);
            padding-inline-start: 10.7rem;
            padding-inline-end: 10rem;
            background: var(--my-account-light-gray);
            display: flex;
            align-items: center;

            @include tablet {
                padding-inline-start: 2.9rem;
                padding-inline-end: 2.8rem;
            }

            @include mobile {
                justify-content: center;
                align-items: center;
                text-align: center;
                height: var(--header-height);
                padding: 0;
            }

            h2 {
                font: 2.4rem var(--font-FFDIN);
                line-height: 3.6rem;
                text-transform: uppercase;
                letter-spacing: -.05rem;
                margin: 0;

                @include isArabic {
                    font: 2.4rem var(--font-DINArabic);
                }

                @include tablet {
                    font-size: 1.7rem;
                }

                @include mobile {
                    font: 1rem var(--font-FFDIN);
                    line-height: 1.8rem;

                    @include isArabic {
                        font: 1rem var(--font-DINArabic);
                    }
                }
            }
        }

        .LoginForm {
            border-right: .2rem solid var(--color-input-border);

            @include isArabic {
                border: none;
            }

            @include mobile {
                display: none;
            }

            @include tablet {
                margin-inline-start: 4rem;
            }

            &-Container {
                margin-inline-end: 10.5rem;

                @include isArabic {
                    margin-inline-end: 6.7rem;
                }

                @include tablet {
                    margin-inline-end: 2rem;
                }

                .LoginTitle {
                    h3 {
                        font: 1.6rem var(--font-FFDIN);
                        line-height: 3.2rem;
                        color: var(--color-gray-title);
                        margin-block-start: 1.3rem;
                        margin-block-end: 2.1rem;
                        letter-spacing: -.05rem;
                        font-weight: bold;

                        @include isArabic {
                            font: 1.6rem var(--font-DINArabic);
                        }

                        &::after {
                            content: '';
                            display: block;
                            padding-block-start: .9rem;
                            border-bottom: .2rem solid var(--color-input-border);
                            width: 18.3rem;

                            @include tablet {
                                width: 15rem;
                                padding-block-start: 0;
                            }
                        }
                    }
                }

                .FieldInput {
                    &-Wrapper {
                        margin-block-end: 5px;

                        input {
                            width: 30rem;

                            @include tablet {
                                width: 23rem;
                                margin-inline-end: 4rem;
                                padding: 1.1rem;
                            }
                        }
                    }

                    &-Error {
                        position: relative;
                        inset-block-end: unset;
                        min-height: 10px;
                        display: inline-flex;
                        align-items: center;
                    }
                }

                .Field {
                    margin-block-end: 5px;

                    .Icon {
                        &-CheckGreen {
                            position: absolute;
                            inset-block-start: 17px;
                            inset-inline-end: -3rem;
                            width: 13px;
                            height: 12px;

                            @include tablet {
                                inset-inline-end: 1rem;
                            }

                            @include isArabic {
                                margin-inline-start: 0;

                                @include tablet {
                                    inset-inline-start: 5rem;
                                    inset-inline-end: unset;
                                }
                            }
                        }
                    }
                }
            }

            &-PolicyMessage {
                padding-block-end: 10px;
                max-width: 30rem;

                @include after-mobile {
                    padding-block-end: 0;
                }

                @include tablet {
                    max-width: 23rem;
                }

                a + a {
                    margin-inline-start: unset;
                }
            }
        }

        .RegisterForm {
            &-Container {
                margin-inline-start: 6.7rem;

                @include isArabic {
                    margin-inline-start: 0;
                    padding-inline-start: 10.5rem;
                    border-right: .2rem solid var(--color-input-border);
                }

                @include mobile {
                    display: none;
                }

                @include tablet {
                    margin-inline-start: 5.7rem;
                }

                .RegistrationTitle {
                    h3 {
                        font: 1.6rem var(--font-FFDIN);
                        line-height: 3.2rem;
                        color: var(--color-gray-title);
                        margin-block-start: 1.3rem;
                        margin-block-end: 2.1rem;
                        letter-spacing: -.05rem;
                        font-weight: bold;

                        @include isArabic {
                            font: 1.6rem var(--font-DINArabic);
                        }

                        &::after {
                            content: '';
                            display: block;
                            padding-block-start: .9rem;
                            border-bottom: .2rem solid var(--color-input-border);
                            width: 18.3rem;

                            @include tablet {
                                width: 19rem;
                                padding-block-start: 0;
                            }
                        }
                    }
                }

                .Field {
                    margin-block-start: 0;
                    margin-block-end: 5px;

                    .Icon {
                        &-CheckGreen {
                            position: absolute;
                            inset-block-start: 17px;
                            inset-inline-end: -3rem;
                            width: 13px;
                            height: 12px;

                            @include tablet {
                                inset-inline-end: 1rem;
                            }

                            @include isArabic {
                                margin-inline-start: 0;

                                @include tablet {
                                    inset-inline-start: 5rem;
                                    inset-inline-end: unset;
                                }
                            }
                        }
                    }

                    &_type_checkbox {
                        margin-block-end: 1rem;
                    }
                }

                .FieldInput {
                    &-Wrapper {
                        margin-block-end: 5px;

                        input {
                            width: 30rem;

                            @include tablet {
                                width: 23rem;
                                margin-inline-end: 4rem;
                                padding: 1.1rem;
                            }
                        }
                    }

                    &-Error {
                        min-height: 10px;
                        inset-block-start: unset;
                        inset-block-end: unset;
                        position: relative;
                        max-width: 320px;
                        display: inline-flex;
                        align-items: center;
                    }
                }
            }
        }

        .Register {
            &-Subscription {
                max-width: 28rem;

                @include tablet {
                    max-width: 23rem;
                }

                @include mobile {
                    max-width: 100%;
                }

                p {
                    line-height: 1.4;
                    margin-inline-start: 3.1rem;

                    a {
                        font-family: var(--font-FFDIN);
                        margin: 0;
                        padding: 0;

                        @include mobile {
                            font-size: .8rem;
                        }

                        @include tablet {
                            font-size: 1rem;
                        }

                        @include isArabic {
                            font-family: var(--font-DINArabic);
                        }
                    }

                    &:last-child {
                        margin-block-end: 3rem;
                    }

                    @include mobile {
                        font: .8rem var(--font-FFDIN);
                        margin-block-start: 1rem;
                        margin-block-end: calc(1rem - 28px);
                        margin-inline-start: 3.1rem;

                        @include isArabic {
                            font: .8rem var(--font-DINArabic);
                        }
                    }

                    @include tablet {
                        font-size: 1rem;
                    }
                }
            }
        }

        .Forgot {
            display: flex;
            justify-content: flex-end;
            inset-block-start: 4rem;

            .Link {
                &-Chart {
                    @include mobile {
                        font: .7rem var(--font-FFDIN);
                        font-weight: bold;
                        width: auto;

                        @include isArabic {
                            font: .7rem var(--font-DINArabic);
                        }

                        &::after {
                            content: '';
                            height: 1px;
                            border-bottom: 1px solid var(--color-black);
                            display: block;
                            margin: 0 auto;

                            &:hover {
                                display: none;
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
