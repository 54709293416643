/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountOverlay {
    &-Checkbox {
        > label:first-of-type {
            font: 1.2rem var(--font-FFDIN);
            font-weight: bold;
            margin-block-start: 3px;

            @include isArabic {
                font: 1.2rem var(--font-DINArabic);

                @include tablet {
                    padding-inline-end: 7px;
                }
            }
        }
    }

    &-Checkbox_isConfirmed {
        z-index: 2;
        width: 28px;
    }

    &-Terms {
        margin-block-end: -20px;
        inset-block-start: -40px;

        span {
            color: $c-orange-jaffa;
        }
    }

    @include desktop {
        inset-inline-end: -33px;
    }
}
